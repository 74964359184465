
    import {defineComponent, ref} from 'vue'

    import store from "@/store/store";
    import MedMenuItem from "@/components/parts/MedMenuItem.vue";
    import {useRouter} from "vue-router";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        name: 'UserProfile',
        components: { MedMenuItem },
        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
        setup () {
          const { t } = useI18n()
          const router = useRouter();
            const fullName: string = store.getters["user/fullName"];

            const logout = async () => {
                await store.dispatch("user/logoutAction");
                await router.push('/auth');
            };

            return {
                t,
                router,
                fullName: ref(fullName),
                avatar: ref(fullName.charAt(0)),
                contact: ref(store.getters["user/emailAddressText"]),
                logout
            }
        }
    });
