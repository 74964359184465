import { createI18n } from 'vue-i18n'
import en from '@/assets/lang/en.json';

// TODO(ML) You are running the esm-bundler build of vue-i18n...
// https://kazupon.github.io/vue-i18n/guide/locale.html

const i18n = createI18n({
    locale:  window.navigator.language,
    fallbackLocale: 'en-US',
    legacy: false,
    messages: {
         'cs-CZ': require('../assets/lang/cs.json'),
         'en-US': en,
         'en-GB': en, // Blimey, let's just sack off british slang, aye mate?
    }
})

export default i18n;

export type Translation = (key: string | number) => string;
