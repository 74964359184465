import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import store from "@/store/store";

import AuthComponent from '../components/pages/Auth.vue'
import HomeComponent from '../components/layouts/Home.vue'
import HelloWorld from "@/components/pages/HelloWorld.vue";
import NotFound from '@/components/pages/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeComponent,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: "HelloWorld",
        component: HelloWorld,
        meta: {"icon": "home", "label": "Home"}
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: NotFound
      },
    ]
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthComponent
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (store.getters["user/loggedIn"] !== true && to.name !== 'Auth') {
    next({name: 'Auth', params: { nextRoute: to.path }});
  } else {
    next();
  }
});

export default router
