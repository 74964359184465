import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cbd9e5ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "minWidth column no-wrap q-pa-md items-center" }
const _hoisted_2 = { class: "text-subtitle1 q-mt-md q-mb-xs" }
const _hoisted_3 = { class: "text-subtitle2 q-mb-xs" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_avatar = _resolveComponent("q-avatar")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_MedMenuItem = _resolveComponent("MedMenuItem")!
  const _component_q_menu = _resolveComponent("q-menu")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    flat: "",
    round: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_avatar, {
        color: "secondary",
        size: "40px"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.avatar), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_q_menu, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_q_avatar, {
              "text-color": "white",
              color: "secondary",
              size: "72px"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.avatar), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.fullName), 1),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.contact), 1),
            _createVNode(_component_q_separator, { class: "minWidth" }),
            _createVNode(_component_MedMenuItem, {
              class: "minWidth",
              clickable: "",
              tag: "a",
              href: "#/user/profile",
              icon: "manage_accounts",
              label: _ctx.t('User.Profile.Edit')
            }, null, 8, ["label"]),
            _createVNode(_component_MedMenuItem, {
              class: "minWidth",
              clickable: "",
              tag: "a",
              href: "#/user/preferences",
              icon: "settings",
              label: _ctx.t('User.Preferences')
            }, null, 8, ["label"]),
            _createVNode(_component_q_separator, { class: "minWidth" }),
            _withDirectives(_createVNode(_component_MedMenuItem, {
              class: "minWidth",
              clickable: "",
              tag: "span",
              onClick: _ctx.logout,
              icon: "logout",
              label: _ctx.t('Auth.Logout')
            }, null, 8, ["onClick", "label"]), [
              [_directive_close_popup]
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}