
    import {defineComponent} from 'vue'

    export default defineComponent({
        name: 'MedMenuItem',
        props: {
            tag: {
                type: String,
                default: "a"
            },
            target: {
                type: String,
                default: ""
            },
            href: {
                type: String,
                default: ""
            },
            icon: {
                type: String,
                default: ""
            },
            label: {
                type: String,
                default: ""
            },
            caption: {
                type: String,
                default: ""
            }
        }
    });
