import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11ebbfb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_linear_progress = _resolveComponent("q-linear-progress")!
  const _component_q_banner = _resolveComponent("q-banner")!
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_layout = _resolveComponent("q-layout")!

  return (_openBlock(), _createBlock(_component_q_layout, {
    view: "hHh lpR fFf",
    class: "flex flex-center orangePage"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, {
        bordered: "",
        id: "loginPanel"
      }, {
        default: _withCtx(() => [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_q_linear_progress, {
                key: 0,
                indeterminate: ""
              }))
            : _createCommentVNode("", true),
          (_ctx.errorMsg !== '')
            ? (_openBlock(), _createBlock(_component_q_banner, {
                key: 1,
                dense: "",
                "inline-actions": "",
                class: "text-white bg-red"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.errorMsg), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                class: "mb-4",
                src: require('@/assets/m3_logo_placeholder.png'),
                alt: "",
                width: "120",
                height: "120"
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator, { inset: "" }),
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createVNode(_component_q_form, {
                onSubmit: _ctx.login,
                class: "q-gutter-md"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_input, {
                    outlined: "",
                    modelValue: _ctx.username,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.username) = $event)),
                    label: _ctx.t('Auth.Username'),
                    required: "",
                    autofocus: ""
                  }, null, 8, ["modelValue", "label"]),
                  _createVNode(_component_q_input, {
                    outlined: "",
                    modelValue: _ctx.password,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.password) = $event)),
                    type: _ctx.isPwd ? 'password' : 'text',
                    label: _ctx.t('Auth.Password')
                  }, {
                    append: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        name: _ctx.isPwd ? 'visibility_off' : 'visibility',
                        class: "cursor-pointer",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isPwd = !_ctx.isPwd))
                      }, null, 8, ["name"])
                    ]),
                    _: 1
                  }, 8, ["modelValue", "type", "label"]),
                  _createVNode(_component_q_btn, {
                    label: _ctx.t('Auth.Login'),
                    type: "submit",
                    color: "primary",
                    disable: _ctx.isLoading
                  }, null, 8, ["label", "disable"])
                ]),
                _: 1
              }, 8, ["onSubmit"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}