import {createStore} from 'vuex'
import user from '@/store/user'
import * as constants from '@/constants'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  modules: ['user'],
})

// typescript vult, see predefined Action type
/* eslint @typescript-eslint/no-explicit-any: "off"*/
export interface RootState {
 [module: string]: any;
 debug: boolean;
}

const store = createStore<RootState>({
  state: {
    debug: constants.DEBUG
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})

// Preserving data already loaded by vuex-persistence
store.registerModule('user', user, {preserveState: !!store.state['user']});

export default store;
