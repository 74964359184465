<template>
    <q-layout view="lHh Lpr lFf">
        <q-header elevated>
            <q-toolbar>
                <q-btn
                        flat
                        dense
                        round
                        @click="drawerOpen = !drawerOpen"
                        aria-label="Menu"
                        icon="menu"
                />

                <q-toolbar-title>
                    Medoro
                </q-toolbar-title>

                <UserProfile></UserProfile>
            </q-toolbar>
        </q-header>

        <MenuDrawer v-model="drawerOpen" />

        <q-page-container>
            <router-view></router-view>
        </q-page-container>
    </q-layout>
</template>

<style scoped>
</style>

<script>
    import { ref } from 'vue'
    import MenuDrawer from '@/components/parts/MenuDrawer.vue'
    import UserProfile from '@/components/parts/UserProfile.vue'

    export default {
        name: 'HomeComponent',

        components: {
            MenuDrawer, UserProfile
        },

        setup () {
            return {
                drawerOpen: ref(false)
            }
        }
    }
</script>
