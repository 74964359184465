<template>
    <div class="text-center" style="padding: 2em">
        <q-img
                src="https://www.rover.com/blog/wp-content/uploads/2014/10/bailey-computer1.png"
                style="width: 60%"
        >
            <div class="absolute-bottom text-subtitle1 text-center">
                {{t('Page.NotFound')}}
            </div>
        </q-img>
    </div>
</template>

<script>
    import {useI18n} from "vue-i18n";
    import {defineComponent} from "vue";

    //TODO(anyone) Proper 404 page would be nice.
    export default defineComponent({
      name: 'NotFound',
      setup() {
        const { t } = useI18n()
        return { t }
      }
    });
</script>

<style scoped>

</style>
