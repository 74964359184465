
    import { defineComponent, ref } from "vue";
    import { useStore } from "vuex";
    import { useRouter, useRoute } from "vue-router";
    import {useI18n} from "vue-i18n";

    export default defineComponent({
        name: 'AuthComponent',
        setup() {
            const { t } = useI18n()

            const store = useStore()
            const router = useRouter()
            const route = useRoute()

            const username = ref()
            const password = ref()
            const errorMsg = ref('')
            const isLoading = ref(false)
            const isPwd = ref(true)

            const selectNextRoute = () => {
                let nextRoute: string | undefined = route.params.nextRoute as string | undefined;
                if (nextRoute === undefined || nextRoute.length === 0 || nextRoute === '/auth') {
                    nextRoute = '/'
                }
                return nextRoute
            }

            const login = async () => {
                isLoading.value = true;

                const loggedIn = await store.dispatch("user/loginAction", {username: username.value, password: password.value})

                if (loggedIn === true) {
                    await router.replace(selectNextRoute())
                } else {
                    errorMsg.value = 'Invalid credentials';
                }

                isLoading.value = false;
            }

            return { t, store, router, route, username, password, errorMsg, isLoading, isPwd, selectNextRoute, login }
        }
    });
