<template>
  <div>
    <router-view />
    <GlobalContextMenu />
  </div>
</template>

<script>
    import {defineComponent} from "vue";
    import GlobalContextMenu from "@/components/parts/GlobalContextMenu";

    export default defineComponent({
        name: 'App',
        components: {GlobalContextMenu}
    })
</script>
