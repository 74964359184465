import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_q_item = _resolveComponent("q-item")!

  return (_openBlock(), _createBlock(_component_q_item, {
    clickable: "",
    tag: _ctx.tag,
    target: _ctx.target,
    href: _ctx.href
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_item_section, { avatar: "" }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_q_icon, { name: _ctx.icon }, null, 8, ["name"]), [
            [_vShow, _ctx.icon.length > 0]
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_q_item_section, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_label, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }),
          _withDirectives(_createVNode(_component_q_item_label, { caption: "" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.caption), 1)
            ]),
            _: 1
          }, 512), [
            [_vShow, _ctx.caption.length > 0]
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["tag", "target", "href"]))
}