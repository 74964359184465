import axios, {setClientToken} from "@/plugins/axios";
import logger from "@/plugins/logger";
import {ActionContext, Module, Store} from "vuex";
import {RootState} from "@/store/store";
import modules from "@/plugins/modules";
import router from "@/plugins/router";

// typescript vult, see predefined Action type
/* eslint @typescript-eslint/no-explicit-any: "off"*/
// unused parameters are necessary, if we need to access later defined ones.
/* eslint @typescript-eslint/no-unused-vars: "off" */

export interface UserState {
    token?: string;
    name?: string;
    surname?: string;
    email?: string;
    permissions?: Array<string>;
}

const user: Module<UserState, RootState> = {
    namespaced: true,
    state: () => ({}) as UserState,
    getters: {
        loggedIn(state: UserState) {
            return (state.token != undefined);
        },
        fullName(state: UserState) {
            return (state.name ?? "Unregistered")+" "+(state.surname ?? "user");
        },
        emailAddressText(state: UserState) {
            return (state.email ?? "Unknown email");
        },
        hasPermission: (state: UserState) => (permission: string) => {
            return state.permissions?.find((perm) => perm === permission) !== undefined;
        },
        hasModule: (state: UserState) => (module: string) => {
            return state.permissions?.find((perm) => perm.startsWith(module)) !== undefined;
        }
    },
    mutations: {
        setUserIdentity (state: UserState, {token, name, surname, email}) {
            state.token = token;
            state.name = name;
            state.surname = surname;
            state.email = email;
            state.permissions = [];
        },
        logout(state: UserState) {
            state.token = undefined;
            state.name = undefined;
            state.surname = undefined;
            state.email = undefined;
            state.permissions = undefined;
        },
        setPermissions (state: UserState, permissions) {
            state.permissions = permissions;
        }
    },
    actions: {
        async loginAction(this: Store<RootState>, _injectee: ActionContext<UserState, RootState>, payload: any) {
            let login = false;

            await axios.post('/auth/signin', { username: payload.username, password: payload.password })
                .then(async (response) => {
                    setClientToken(response.data.token);

                    this.commit('user/setUserIdentity', {
                        token: response.data.token,
                        name: response.data.name,
                        surname: response.data.surname,
                        email: response.data.email
                    });

                    await axios.post('/user/permissions',).then(response => {
                        this.commit('user/setPermissions', response.data);
                    }).catch(error => {
                        logger.error(error.response);
                    });

                    //console.log(jwtdecode(response.data.token));

                    login = true;
                }).catch(error => {
                    logger.error(error.response);
                });

            if (login) {
                await modules.onLogin();
            }

            return login;
        },
        async autoLoginAction(this: Store<RootState>) {
            if (this.getters["user/loggedIn"] == true) {
                setClientToken(this.state.user.token);

                await axios.post('/auth/validate-token').catch(async () => {
                    await this.dispatch('user/logoutAction');
                })
            }
        },
        async logoutAction(this: Store<RootState>) {
            setClientToken(undefined);
            this.commit('user/logout');

            await modules.onLogout();
        }
    }
}

export default user;
