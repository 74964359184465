import { createApp } from 'vue'
import App from './App.vue'

import store from '@/store/store'
import router from '@/plugins/router'
import logger from "@/plugins/logger";
import axios from '@/plugins/axios'
import i18n from '@/plugins/i18n'
import modules from "@/plugins/modules";

import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from '@/quasar-user-options'

async function verifyAuthToken() {
    await store.dispatch("user/autoLoginAction");

    if (store.getters["user/loggedIn"] !== true && router.currentRoute.value.name !== 'Auth') {
        await router.push({name: 'Auth', params: { nextRoute:  router.currentRoute.value.path }});
    }
}

modules.onAppLoad().then(async () => {
    createApp(App).use(Quasar, quasarUserOptions)
        .use(store)
        .use(router)
        .use(i18n)
        .provide('logger', logger)
        .provide('axios', axios)
        .provide('modules', modules)
        .mount('#app')

    await verifyAuthToken()
})
