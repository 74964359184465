import {AppModule} from "@/lib/AppModule";
import adminRoutes from "@/modules/admin/AdminRoutes";
import store from "@/store/store";

class AdminModule extends AppModule {

    static readonly REQUIRED_PERM_MODULE = "M3.ADMIN.USERS";

    constructor(name: string) {
        super(name);
    }

    async activateModuleImpl(): Promise<boolean> {
        this.activateRoutes(adminRoutes);
        return Promise.resolve(true);
    }

    async deactivateModuleImpl(): Promise<boolean> {
        this.deactivateRoutes(adminRoutes);
        return Promise.resolve(true);
    }

    isAvailable(): boolean {
        return store.getters["user/hasModule"](AdminModule.REQUIRED_PERM_MODULE) === true;
    }

}

const adminModule: AdminModule = new AdminModule("admin");

export default adminModule;
