import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_MedMenuItem = _resolveComponent("MedMenuItem")!
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_q_item = _resolveComponent("q-item")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createBlock(_component_q_select, {
    filled: "",
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    clearable: "",
    "use-input": "",
    "input-debounce": "500",
    "hide-dropdown-icon": true,
    label: _ctx.t('Search'),
    options: _ctx.options,
    onFilter: _ctx.filterFnAutoselect,
    style: {"width":"250px"}
  }, {
    prepend: _withCtx(() => [
      _createVNode(_component_q_icon, { name: "search" })
    ]),
    option: _withCtx((scope) => [
      _createVNode(_component_MedMenuItem, _mergeProps(scope.itemProps, {
        icon: scope.opt.icon,
        label: scope.opt.label,
        caption: scope.opt.caption,
        href: scope.opt.href
      }), null, 16, ["icon", "label", "caption", "href"])
    ]),
    "no-option": _withCtx(() => [
      _createVNode(_component_q_item, null, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_section, { class: "text-grey" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('NoResults')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue", "label", "options", "onFilter"]))
}