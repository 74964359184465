 export const DEBUG = true;
 export const VERSION = '0.0.1';
 export const VUE_TS_BS_COUNTER = 3;

 export const OK = 200;
 export const CREATED = 201;

 export const BAD_REQUEST = 400;
 export const UNAUTHORIZED = 402;
 export const FORBIDDEN = 403;
 export const NOT_FOUND = 404;
 export const CONFLICT = 409;

 export const INTERNAL_SERVER_ERROR = 500;
