import axios from 'axios'
import logger from "@/plugins/logger";

function initHttpAxiosClient() {
    // TODO init BASE URL
    const baseUrl = "https://m3.dev.medoro.cz/api/";//process.env !== 'production' ? "http://localhost:8081" : "http://localhost:8081";
    logger.log(process.env);
    logger.log("Axios base server URL: " + baseUrl);

    return axios.create({
        baseURL: baseUrl
    });
}

const axiosInstance = initHttpAxiosClient();

export default axiosInstance;

export function setClientToken(token: string | undefined) {
    axiosInstance.defaults.headers.common.Authorization = token == undefined ? '' : `Bearer ${token}`;
}
