
import {defineComponent} from "vue";
import {useI18n} from "vue-i18n";
import MedMenuItem from "@/components/parts/MedMenuItem.vue";
import SearchInput from "@/components/parts/search/SearchInput.vue";
import {RouteSeeker, TestSeeker} from "@/lib/Search";
import {useRouter} from "vue-router";


export default defineComponent({
  name: "GlobalContextMenu",
  components: {MedMenuItem, SearchInput},
  setup() {
    const { t } = useI18n()
    const router = useRouter();
    return {
      t,
      testSeeker: new TestSeeker(),
      routeSeeker: new RouteSeeker(t, router),
    }
  }
})
