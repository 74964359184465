
    import {defineComponent, ref} from 'vue'

    import store from "@/store/store";
    import MedMenuItem from "@/components/parts/MedMenuItem.vue";
    import {useI18n} from "vue-i18n";
    import adminModule from "@/modules/admin/AdminModule";

    export default defineComponent({
        name: 'MenuDrawer',
        components: { MedMenuItem },
        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
        setup () {
          const { t } = useI18n()
          return {
                t,
                isDevelopment: ref(store.state.debug),
                isAdmin: ref(adminModule.isAvailable())
            }
        }
    });
