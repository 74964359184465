import {Router, RouteRecord} from "vue-router";
import {Translation} from "@/plugins/i18n";

export interface Seeker {
    find(filter: string): Promise<Array<Option>>;
}

export interface Option {
    icon: string;
    label: string;
    caption: string;
    href: string;
}

export class TestSeeker implements Seeker {

    async find(filter: string) {
        const needle = filter.toLowerCase()

        return [
            {icon: 'google', label: 'Google', caption: 'It knows everything! Including your porn history...', href: 'whatever'},
            {icon: 'facebook', label: 'Facebook', caption: 'All your data are belong to us!', href: 'whatever'},
            {icon: 'twitter', label: 'Twitter', caption: 'You daily batch of nonsense!', href: 'whatever'},
            {icon: 'apple', label: 'Apple', caption: 'One Apple a day keeps common sense away...', href: 'whatever'},
            {icon: 'oracle', label: 'Oracle', caption: 'Also known as Obstacle', href: 'whatever'},
        ].filter((record) =>
            record.caption?.toLowerCase()?.indexOf(needle) > -1
            ||  record.label?.toLowerCase()?.indexOf(needle) > -1
        );
    }

}

export class RouteSeeker implements Seeker {

    t: Translation;
    router: Router;

    constructor(t: Translation, router: Router) {
        this.t = t;
        this.router = router;
    }

    async find(filter: string) {
       return this.router.getRoutes()
           .filter((route) => this.routeMatches(filter, route))
           .map((route) => this.route2Option(route));
    }

    routeMatches(filter: string, route: RouteRecord): boolean {
        const needle = filter.toLowerCase()
        const caption = this.translateItem(route, 'caption');
        const label = this.translateItem(route, 'label');

        return ((caption.length > 0) && caption?.toLowerCase()?.indexOf(needle) > -1)
            || ((label.length > 0) && label?.toLowerCase()?.indexOf(needle) > -1) ;
    }

    route2Option(route: RouteRecord): Option {
       return {
           icon: (route.meta['icon'] ?? '') as string,
           label: this.translateItem(route, 'label'),
           caption: this.translateItem(route, 'caption'),
           href: '#'+route.path
       };
    }

    translateItem(route: RouteRecord, itemKey: string): string {
        const lang: string = route.meta[itemKey] as string;
        return (lang != undefined && lang?.length > 0) ? this.t(lang) : '';
    }
}
