import adminModule from "@/modules/admin/AdminModule";

interface AppModuleManager {

    onAppLoad(): Promise<boolean>;
    onLogin(): Promise<boolean>;
    onLogout(): Promise<boolean>;

}

class AppModuleManagerImpl implements AppModuleManager {

    onAppLoad(): Promise<boolean> {
        if (adminModule.isAvailable()) {
            return adminModule.activateModuleImpl();
        }
        return Promise.resolve(true);
    }

    onLogin(): Promise<boolean> {
        if (adminModule.isAvailable()) {
            return adminModule.activateModule();
        }
        return Promise.resolve(true);
    }

    onLogout(): Promise<boolean> {
        if (adminModule.isActive) {
            return adminModule.deactivateModule();
        }
        return Promise.resolve(true);
    }

}

const modules: AppModuleManager = new AppModuleManagerImpl();
export default modules;
