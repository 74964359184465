import {RouteRecordRaw} from "vue-router";

const adminRoutes: Array<RouteRecordRaw> = [
    {
        path: '/admin/users',
        name: "Users",
        component: () => import(/* webpackChunkName: "userModule" */ './components/pages/Users.vue'),
        meta: {"injectionPath": "/", "icon": "people", "label": "Users.Title"}
    },
    {
        path: '/admin/users/add',
        name: "AddUser",
        component: () => import(/* webpackChunkName: "userModule" */ './components/pages/UserForm.vue'),
        meta: {"injectionPath": "/", "icon": "person_add", "label": "Users.Form.NameNew"}
    },
    {
        path: '/admin/users/edit/:id',
        name: "EditUser",
        component: () => import(/* webpackChunkName: "userModule" */ './components/pages/UserForm.vue'),
        props: true,
        meta: {"injectionPath": "/"}
    },
    {
        path: '/admin/users/permissions/edit/:id',
        name: "EditUserPermissions",
        component: () => import(/* webpackChunkName: "userModule" */ './components/pages/UserPermissionsEditor.vue'),
        props: true,
        meta: {"injectionPath": "/"}
    },
    {
        path: '/admin/groups',
        name: "Groups",
        component: () => import(/* webpackChunkName: "userModule" */ './components/pages/Groups.vue'),
        meta: {"injectionPath": "/", "icon": "groups", "label": "Groups.Title"}
    },
    {
        path: '/admin/groups/add',
        name: "AddGroup",
        component: () => import(/* webpackChunkName: "userModule" */ './components/pages/GroupForm.vue'),
        meta: {"injectionPath": "/", "icon": "group_add", "label": "Groups.Form.TitleNew"}
    },
    {
        path: '/admin/groups/edit/:id',
        name: "EditGroup",
        component: () => import(/* webpackChunkName: "userModule" */ './components/pages/GroupForm.vue'),
        props: true,
        meta: {"injectionPath": "/"}
    },
    {
        path: '/admin/users/permissions/:id',
        name: "UserPermissions",
        component: () => import(/* webpackChunkName: "userModule" */ './components/pages/UserPermissions.vue'),
        meta: {"injectionPath": "/"}
    }
]

export default adminRoutes;
