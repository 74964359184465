/**
 * Vue logger may be nice, but it only works within Vue instances. No thanks.
 * We are using global object, which can be by the way injected into Vue too.
 *
 * BEHAVES DIFFERENTLY WHEN RUNNING 'PRODUCTION' BUILD.
 */
export interface Logger {
    level: Level;
    error: (message: string) => void;
    warn: (message: string) => void;
    log: (message: string, level?: Level) => void;
}

export enum Level {
    INFO,
    WARN,
    ERROR,
}

export class LoggerInstance implements Logger {
    level: Level;

    constructor(level: Level) {
        this.level = level;
    }

    error(message: string): void {
        this.log(message, Level.ERROR)
    }

    warn(message: string): void {
        this.log(message, Level.WARN)
    }

    log(message: string, level?: Level): void {
        if (this.level <= (level ?? Level.INFO)) {
            this.write2Console(message, level);
        }
    }

    write2Console(message: string, level?: Level): void {
        if (level === Level.ERROR) {
            console.error(message);
        } else if (level === Level.WARN) {
            console.warn(message);
        } else {
            console.log(message);
        }
    }
}

function createGlobalLogger(): Logger {
    const isProduction = (process.env !== 'production');
    return new LoggerInstance( isProduction ? Level.WARN : Level.INFO)
}

const logger: Logger = createGlobalLogger();

export default logger;
