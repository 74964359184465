import store, { RootState } from "@/store/store";
import router from "@/plugins/router";
import user from "@/store/user";
import { Module } from "vuex";
import { RouteRecordRaw } from "vue-router";
import logger, { Level } from "@/plugins/logger";

export abstract class AppModule {

    protected _name: string;
    protected _isActive = false;

    protected constructor(name: string) {
        this._name = name;
    }

    async activateModule(): Promise<boolean> {
        if (!this._isActive) {
            this._isActive = await this.activateModuleImpl();
            logger.log("Loaded module "+this._name, Level.INFO);
        } else {
            logger.log("Attempting to load already loaded module "+this._name, Level.WARN);
        }
        return this._isActive;
    }

    abstract activateModuleImpl(): Promise<boolean>;

    protected activateStoreModule<T>(moduleName: string, module: Module<T, RootState>, {preserveState = false}) {
        const preserveButDontReload = preserveState ? !!store.state[moduleName] : false;
        store.registerModule(moduleName, user, {preserveState: preserveButDontReload});
    }

    protected activateRoutes(routes: Array<RouteRecordRaw>) {
        routes.forEach((route) => {
            const injectionPath = (route.meta !== undefined)
                ? route.meta["injectionPath"]
                : undefined;

            const injectionParent = (injectionPath !== undefined)
                ? router.getRoutes().find((route) => route.path === injectionPath)
                : undefined;

            if (injectionParent !== undefined) {
                injectionParent.children.push(route);
                router.addRoute(injectionParent);
            } else {
                router.addRoute(route);
            }
        });
    }

    async deactivateModule(): Promise<boolean> {
        if (this._isActive) {
            this._isActive = !(await this.deactivateModuleImpl());
            logger.log("Unloaded module "+this._name, Level.INFO);

        } else {
            logger.log("Attempting to load inactive module "+this._name, Level.WARN);
        }
        return !this._isActive;
    }

    abstract deactivateModuleImpl(): Promise<boolean>;

    protected deactivateStoreModule(moduleName: string) {
        store.unregisterModule(moduleName);
    }

    protected deactivateRoutes(routes: Array<RouteRecordRaw>) {
        routes.forEach((record) => {
            if (record.name !== undefined) {
                router.removeRoute(record.name);

                const injectionPath = (record.meta !== undefined)
                    ? record.meta["injectionPath"]
                    : undefined;

                const injectionParent = (injectionPath !== undefined)
                    ? router.getRoutes().find((route) => route.path === injectionPath)
                    : undefined;

                if (injectionParent !== undefined) {
                    const routeIndex =  injectionParent.children.indexOf(record);
                    if (routeIndex >= 0) injectionParent.children.splice(routeIndex, 1);
                } else {
                    router.removeRoute(record.name);
                }
            }
        });
    }

    abstract isAvailable(): boolean;

    get name(): string {
        return this._name;
    }

    get isActive(): boolean {
        return this._isActive;
    }

}
