
import {defineComponent, PropType, ref} from 'vue'
import MedMenuItem from "@/components/parts/MedMenuItem.vue";
import {useI18n} from "vue-i18n";
import {Option, Seeker} from '@/lib/Search';
import QSelect from 'quasar/src/components/select/QSelect.js';;

export default defineComponent({
  name: "SearchInput",
  components: {MedMenuItem},
  props: {
    seekers: {
      type: Object as PropType<Array<Seeker>>,
      required: true
    }
  },
  setup (props) {
    const { t }= useI18n();
    const options = ref<Option[]>([]);

    const filter = (val: string) => {
      Promise.all(
          props.seekers.map((seeker) => seeker.find(val))
      ).then((result: Array<Option>[]) => {
        options.value = result.reduce((prev, act) => prev.concat(act));
      });
    };

    const filterFnAutoselect = (val: string, update: FilterUpdateFunction, abort: () => void) => {
      update(
          () => {
            if (val === '') {
              abort()
            } else {
              filter(val)
            }
          },
          ref => { // "ref" is the Vue reference to the QSelect
            if (val !== '' && ref?.options != undefined && ref.options.length > 0 && ref?.optionIndex != undefined && ref.optionIndex === -1) {
              ref.moveOptionSelection(1, true) // focus the first selectable option and do not update the input-value
              ref.toggleOption(ref.options[ ref.optionIndex ], true) // toggle the focused option
            }
          }
      )
    };

    return {
      t,
      model: ref(null),
      options,
      filterFnAutoselect
    }
  }
})

type FilterUpdateFunction = (filter: () => void, ref: (ref: QSelectWorkaround) => void) => void;

interface QSelectWorkaround extends QSelect{
  // Missing in Beta Quasar TS declarations, but present in tutorials
  optionIndex: number;
}

