import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_label = _resolveComponent("q-item-label")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_MedMenuItem = _resolveComponent("MedMenuItem")!
  const _component_q_list = _resolveComponent("q-list")!
  const _component_q_menu = _resolveComponent("q-menu")!

  return (_openBlock(), _createBlock(_component_q_menu, {
    "touch-position": "",
    "context-menu": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_list, {
        dense: "",
        style: {"min-width":"100px"}
      }, {
        default: _withCtx(() => [
          _createVNode(_component_q_item_label, {
            header: "",
            class: "header"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('FastActions.Actions')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_SearchInput, {
            seekers: [_ctx.testSeeker, _ctx.routeSeeker]
          }, null, 8, ["seekers"]),
          _createVNode(_component_q_item_label, {
            header: "",
            class: "header"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t('FastActions.Links')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_MedMenuItem, {
            clickable: "",
            tag: "a",
            href: "#/home",
            icon: "home",
            label: _ctx.t('Home')
          }, null, 8, ["label"]),
          _createVNode(_component_MedMenuItem, {
            clickable: "",
            tag: "a",
            href: "#/admin/users",
            icon: "people",
            label: _ctx.t('Users.Title')
          }, null, 8, ["label"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}